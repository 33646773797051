<template>
  <div id="error">
    <img src="../../assets/errorimg/bird.png" alt="" class="bird3" />
    <img src="../../assets/errorimg/direction.png" alt="" class="direction" />
    <img src="../../assets/errorimg/cloud.png" alt="" class="cloud" />
    <img src="../../assets/errorimg/bird.png" alt="" class="bird1" />
    <img src="../../assets/errorimg/bird.png" alt="" class="bird2" />
    <h1>404</h1>
    <p class="whoops">Whoops!</p>
    <p class="mb-0">You seem to have lost your way</p>
    <p>Let’s get you back</p>
    <v-btn outlined class="mt-3" @click="$router.push({name:'HomePage'})">Go Home</v-btn>
    <img src="../../assets/errorimg/road.png" class="road" alt="" />
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
  data(){}
};
</script>

<style lang="scss" scoped>
#error {
  height: 100vh;
  width: 100vw;
  text-align: center;
  padding-top: 200px;
  @media (max-width: 1024px) {
    padding-top: 15%;
  }
}
h1 {
  font-family: "Raleway",sans-serif;
  font-weight: 700;
  font-size: 230px;
  color: #4f4f4f;
  padding: 10px 0;
  margin-top: -2rem;
  @media (max-width: 567px) {
    font-size: 200px;
  }
}
.whoops {
  font-family: "Inter",sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #4f4f4f;
  padding: 10px 0;
  margin-top: -3rem;
}
p {
  font-family: "Inter",sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #4f4f4f;
  z-index: 1;
}
.road {
  width: 100%;
  background-size: contain;
  bottom: 0;
  z-index: 0;
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.direction {
  height: 500px;
  left: 80%;
  background-size: contain;
  position: absolute;
  z-index: 100;
  bottom: 0;
  @media (max-width: 1024px) {
    height: 350px;
  }
  @media (max-width: 567px) {
    left: 65%;
    height: 300px;
  }
}
.cloud {
  width: 100%;
  position: absolute;
  text-align: center;
  left: 400px;
  top: 0;
  @media (max-width: 1440px) {
    left: 10%;
    top: 60px;
  }
  @media (max-width: 768px) {
    left: 0;
    width: 1500px;
  }
}
.bird1 {
  position: absolute;
  width: 30px;
  left: 400px;
  @media (max-width: 768px) {
    left: 5%;
  }
}
.bird2 {
  position: absolute;
  width: 30px;
  left: 700px;
  top: 50px;
  @media (max-width: 768px) {
    left: 51%;
  }
}
.bird3 {
  position: absolute;
  width: 30px;
  left: 1000px;
  top: 100px;
  @media (max-width: 768px) {
    left: 75%;
  }
}
.v-btn {
  z-index: 1200;
}
</style>